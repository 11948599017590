import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import header_background from "../assets/header_background.jpg";
import frame_lines from "../components/svg/frame_lines.png"
import frame_dots_long from "../components/svg/frame_dots_long.png"
import header_about_us from "../assets/header_about_us.jpg";
import {useContext} from "react";
import {LanguageContext} from "../helpers/LanguageContext";
import LANGUAGES from "../helpers/translate";

export default function ProBono() {

    const {currentLanguage, changeLanguage} = useContext(LanguageContext);

    return (
        <div>
            <Sidebar></Sidebar>
            {/* HEADER */}

            <div className="relative h-[350px] md:h-[400px] max-h-2xl w-full">
                <img src={header_about_us} alt="Background Image"
                     className="absolute inset-0 w-full h-full object-cover filter"/>
                <div className="absolute inset-0 bg-black bg-opacity-50"></div>
                <div
                    // style={{ backgroundImage: `url(${background})` }}
                    className="absolute top-0 right-0 bottom-0 left-0 w-full flex flex-col items-center justify-center my-auto">
                    <img className="hidden lg:flex absolute h-10 md:h-12 top-24 right-0 md:right-6" src={frame_lines}/>
                    <div className="flex md:hidden mx-auto">
                        {/*<img*/}
                        {/*    className="lg:w-full h-full max-h-[15rem] xl:max-h-[25rem] 2xl:max-h-[40rem] overflow-hidden object-cover bg-cover bg-no-repeat"*/}
                        {/*    src={photo1}/>*/}
                    </div>
                    <div className="w-full flex flex-col justify-center pt-12">
                        <h1 className="text-center text-3xl lg:text-4xl text-white font-bold">Pro Bono</h1>
                        <span className='w-24 mx-auto bg-white h-1 mt-1.5 rounded-lg'/>
                        {/*<span*/}
                        {/*    className=" text-start text-base lg:text-lg text-white font-medium mt-4">Iata cateva informatii despre cine sunt noi</span>*/}
                    </div>
                    <div className="hidden lg:flex w-3/12">
                        {/*<img*/}
                        {/*    className="lg:w-8/12 max-w-xl h-full mx-auto max-h-[24rem] object-cover bg-cover bg-no-repeat"*/}
                        {/*    src={photo1}/>*/}
                    </div>
                </div>
            </div>

            <div className="w-full ">
                <div className="h-5"></div>

                <div className={currentLanguage === "ro" || !currentLanguage ? 'w-11/12 mx-auto container' : 'hidden'}>
                    <p className="text-lg">
                        „Expresia <span className="italic font-bold">„pro bono”</span> provine din latinescul <span
                        className="italic font-bold">„pro bono
                        publico”</span>, care înseamnă <span
                        className="italic font-bold">„pentru binele public”</span>. În acest spirit, conceptul
                        reprezintă un act de
                        solidaritate și responsabilitate socială, prin care avocații își pot aduce contribuția la binele
                        comunității și la apărarea drepturilor fundamentale ale omului.
                    </p>

                    <p className="mt-5 text-lg">
                        Deși în România activitatea juridică pro bono nu este reglementată oficial, aceasta este permisă
                        și recunoscută, diferențiindu-se de ajutorul public judiciar, care este oferit de stat în
                        anumite cazuri. Asistența juridică pro bono se bazează pe inițiativa avocatului, în conformitate
                        cu principiile Codului deontologic al avocaților din Uniunea Europeană și cu reglementările
                        Uniunii Naționale a Barourilor din România (Decizia U.N.B.R. Nr. 1486/2007). Aceste norme
                        subliniază că profesia de avocat reprezintă un mijloc esențial de apărare a drepturilor omului
                        în fața statului și a altor puteri din societate. Credem că fiecare persoană are dreptul la
                        apărare, indiferent de situația financiară, și susținem un sistem de justiție echitabil, în care
                        solidaritatea profesională joacă un rol important
                    </p>

                    <p className="mt-5 text-lg">
                        În final, dorim să reafirmăm că suntem pregătiți să oferim asistență juridică gratuită
                        persoanelor vulnerabile, fără surse de venit sau mijloace financiare suficiente, în spiritul
                        valorilor noastre profesionale și sociale.
                        Dacă vă aflați într-o situație dificilă și considerați că aveți nevoie de asistență sau
                        reprezentare juridică, dar situația financiară nu vă permite angajarea unui avocat, vă invităm
                        să completați formularul disponibil

                    </p>

                    <p className="mt-5 text-lg">
                        Unul dintre colegii noștri vă va contacta în cel mai scurt timp pentru a stabili o întâlnire la
                        sediul nostru, unde vom analiza cazul dumneavoastră în condiții de confidențialitate. Ne asumăm
                        angajamentul de a sprijini comunitatea și de a apăra drepturile celor care au cea mai mare
                        nevoie de ajutor juridic.”
                    </p>
                </div>

                <div className={currentLanguage === "en" ? 'w-11/12 mx-auto container' : 'hidden'}>
                    <p className="text-lg">
                        "The expression <span className="italic font-bold">"pro bono"</span> comes from the Latin <span
                        className="italic font-bold">"pro bono
publico"</span>, which means <span
                        className="italic font-bold">"for the public good"</span>. In this spirit, the concept
                        represents an act of
                        solidarity and social responsibility, through which lawyers can contribute to the good
                        of the community and the defense of fundamental human rights.
                    </p>

                    <p className="mt-5 text-lg">
                        Although pro bono legal activity is not officially regulated in Romania, it is allowed
                        and recognized, differentiating itself from public legal aid, which is provided by the state in
                        certain cases. Pro bono legal assistance is based on the lawyer's initiative, in accordance
                        with the principles of the Code of Ethics for Lawyers in the European Union and with the
                        regulations
                        of the National Union of Bars of Romania (U.N.B.R. Decision No. 1486/2007). These norms
                        emphasize that the legal profession represents an essential means of defending human rights
                        before the state and other powers in society. We believe that every person has the right to
                        defense, regardless of their financial situation, and we support a fair justice system, in which
                        professional solidarity plays an important role
                    </p>

                    <p className="mt-5 text-lg">
                        Finally, we would like to reaffirm that we are ready to provide free legal assistance
                        to vulnerable people, without sufficient sources of income or financial means, in the spirit
                        of our professional and social values.
                        If you are in a difficult situation and consider that you need legal assistance
                        representation, but your financial situation does not allow you to hire a lawyer, we invite you
                        to fill out the available form
                    </p>

                    <p className="mt-5 text-lg">
                        One of our colleagues will contact you shortly to set up a meeting at our office, where we will
                        discuss your case confidentially. We are committed to supporting the community and defending the
                        rights of those who need legal help the most.”
                    </p>
                </div>


                <div className="h-5"></div>
            </div>
            <Footer></Footer>
        </div>
    )
}