import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import header_1 from "../components/svg/header_1.png";
import icon_email from "../components/svg/icon_email.svg";
import icon_phone from "../components/svg/icon_phone.svg";
import {useContext, useEffect, useState} from "react";
import bg_photo_1 from "../assets/building.jpg";
import photo1 from "../components/svg/header_photo_1.png";
import header_contact from "../assets/header_contact.jpg"
import {LanguageContext} from "../helpers/LanguageContext";
import LANGUAGES from "../helpers/translate";
import axios from "axios";
import {getToken} from "./helpers/auth";

export default function Contact() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const { currentLanguage, changeLanguage } = useContext(LanguageContext);

    const t = LANGUAGES[currentLanguage]; // Active translations.

    const [formContact, setFormContact] = useState({})

    const handleChange = text => e => {
        // user({type: text, value: e.target.value})
        setFormContact({...formContact, [text]: e.target.value})
    }

    const [onSuccessMessage, setSuccessMessage] = useState({success: false, message: ""});

    const handleSubmit = e => {
        e.preventDefault();
        setFormContact(() => ({email: "", message: "", subject: ""}))

        if (!formContact.message || !formContact.subject || !formContact.email) {
            return setSuccessMessage(() => ({
                success: false,
                message: 'Introduceti toate informatiile'
            }))
        }

        axios
            .post(`${process.env.REACT_APP_API_URL}/contact`, {
                email: formContact.email,
                message: formContact.message,
                subject: formContact.subject,
            }, {})
            .then((result) => {
                // const new_blog = result.data;
                // setData([...data, new_blog]);
                // setAddModal(false);
                console.log("result", result)
                setSuccessMessage(() => ({
                    success: true,
                    message: 'Mesaj trimis cu succes'
                }))
            })
            .catch((err) => {
                console.log("err", err)
                setSuccessMessage(() => ({
                    success: false,
                    message: 'Email-ul nu a fost trimis'
                }))
            });
    }

    return (
        <div>
            <Sidebar/>

            {/*<div className="py-12 bg-blue">*/}
            {/*    <div*/}
            {/*        className="w-full flex flex-col md:flex-row items-center justify-center my-auto pt-5 xl:pt-10">*/}
            {/*        <div className="flex md:hidden mx-auto w-8/12">*/}
            {/*            <img*/}
            {/*                className="w-full h-full max-h-[15em] xl:max-h-[37rem] 2xl:max-h-[60rem] overflow-hidden object-cover bg-cover bg-no-repeat"*/}
            {/*                src={header_1}/>*/}
            {/*        </div>*/}
            {/*        <div className="w-10/12 md:w-6/12 flex flex-col justify-start pt-7">*/}
            {/*            <span className="md:w-8/12 text-start text-3xl lg:text-4xl font-bold text-white">Contactați-ne chiar acum</span>*/}
            {/*            <span className="md:w-10/12 text-start text-base lg:text-lg text-white font-medium mt-4">Conectați-vă acum pentru asistență rapidă și descoperiți beneficiile de a ne contacta imediat pentru soluții personalizate.</span>*/}

            {/*            <div className="flex flex-row pt-8">*/}
            {/*                <div className="bg-white w-12 p-2.5 rounded-full">*/}
            {/*                    <img className="w-10" src={icon_email}/>*/}
            {/*                </div>*/}
            {/*                <div className="flex flex-col ml-4">*/}
            {/*                    <span className="text-white font-bold">Office Email</span>*/}
            {/*                    <span className="text-[#9CA2A5]">office@paulmoise.ro</span>*/}
            {/*                </div>*/}
            {/*            </div>*/}

            {/*            <div className="flex flex-row pt-4">*/}
            {/*                <div className="bg-white flex items-center w-12 p-2.5 rounded-full">*/}
            {/*                    <img className="w-10" src={icon_phone}/>*/}
            {/*                </div>*/}
            {/*                <div className="flex flex-col ml-4">*/}
            {/*                    <span className="text-white font-bold">Office Numar de telefon</span>*/}
            {/*                    <span className="text-[#9CA2A5]">0776-251-822</span>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className="hidden md:flex w-4/12">*/}
            {/*            <img*/}
            {/*                className="w-full max-h-[39rem] xl:max-h-[37rem] 2xl:max-h-[60rem] overflow-hidden object-cover bg-cover bg-no-repeat"*/}
            {/*                src={header_1}/>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <div className="relative h-[600px] md:h-[500px] max-h-2xl w-full">
                <img src={header_contact} alt="Background Image"
                     className="absolute inset-0 w-full h-full object-cover filter"/>
                <div className="absolute inset-0 bg-black bg-opacity-50"></div>
                <div
                    // style={{ backgroundImage: `url(${background})` }}
                    className="absolute top-0 right-0 bottom-0 left-0 w-full flex flex-col md:flex-row items-center justify-center my-auto">
                    {/*<div className="flex md:hidden mx-auto">*/}
                    {/*    <img*/}
                    {/*        className="lg:w-full h-full max-h-[15rem] xl:max-h-[25rem] 2xl:max-h-[40rem] overflow-hidden object-cover bg-cover bg-no-repeat"*/}
                    {/*        src={photo1}/>*/}
                    {/*</div>*/}
                    {/*<div className="w-11/12 lg:w-6/12 flex flex-col justify-start pt-7">*/}
                    {/*    <span className="md:w-8/12 text-start text-3xl lg:text-4xl text-white font-bold">Sutem aici pentru obiectivul tau</span>*/}
                    {/*    <span className="md:w-10/12 text-start text-base lg:text-lg text-white font-medium mt-4">Experimentați o creștere a activităților dvs. cu consultanța noastră de specialitate. Excelem în adaptarea strategiilor de succes la obiectivele dumneavoastră unice, acoperind domenii diverse pentru un impact maxim, profesional și încântător.</span>*/}
                    {/*    <button*/}
                    {/*        className="w-[9rem] font-semibold flex items-center justify-center bg-blue text-[16px] text-white rounded-xl py-2 px-2 mt-6">*/}
                    {/*        <span className="">Aflați mai multe</span>*/}
                    {/*    </button>*/}
                    {/*</div>*/}
                    {/*<div className="hidden lg:flex w-3/12">*/}
                    {/*    <img*/}
                    {/*        className="lg:w-8/12 max-w-xl h-full mx-auto max-h-[24rem] object-cover bg-cover bg-no-repeat"*/}
                    {/*        src={photo1}/>*/}
                    {/*</div>*/}
                    <div className="absolute w-10/12 md:w-9/12 flex flex-col justify-start pt-7">
                        <span className="md:w-8/12 text-start text-3xl lg:text-4xl font-bold text-white">{t.contact_right_now}</span>
                        <span className="md:w-10/12 text-start text-base lg:text-lg text-white font-medium mt-4">{t.subtitle_contact_right_now}</span>

                        <div className="flex flex-row pt-8">
                            <div className="bg-white w-12 p-2.5 rounded-full">
                                <img className="w-10" src={icon_email}/>
                            </div>
                            <div className="flex flex-col ml-4">
                                <span className="text-white font-bold">Office Email</span>
                                <span className="text-white">office@moisepaul.ro</span>
                            </div>
                        </div>

                        <div className="flex flex-row pt-4">
                            <div className="bg-white flex items-center w-12 p-2.5 rounded-full">
                                <img className="w-10" src={icon_phone}/>
                            </div>
                            <div className="flex flex-col ml-4">
                                <span className="text-white font-bold">{t.phoneNo}</span>
                                <a href="tel:+4 (0) 743 121 815" className="text-white">+4 (0) 743 121 815</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="text-gray-600 body-font relative">
                <div className="absolute inset-0 bg-gray-300">
                    {/*<iframe className="opacity-40" width="100%" height="100%" frameBorder="0" marginHeight="0" marginWidth="0" title="map"*/}
                    {/*        scrolling="no"*/}
                    {/*        src="https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=en&amp;q=%C4%B0zmir+(My%20Business%20Name)&amp;ie=UTF8&amp;t=&amp;z=14&amp;iwloc=B&amp;output=embed"*/}
                    {/*></iframe>*/}
                    <div className="h-full w-full">
                        <iframe width="100%" className="h-full w-full" frameBorder="0" scrolling="no" marginHeight="0"
                                marginWidth="0"
                                src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Strada%20Mihai%20Eminescu%203,%20Oradea+(Avocat%20Moise%20Paul)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
                            <a href="https://www.gps.ie/">gps trackers</a></iframe>
                    </div>

                    {/*<div style="width: 100%">*/}
                    {/*    <iframe width="100%" height="600" frameBorder="0" scrolling="no" marginHeight="0"*/}
                    {/*            marginWidth="0"*/}
                    {/*            src="">*/}
                    {/*        <a href="https://www.gps.ie/">gps tracker sport</a></iframe>*/}
                    {/*</div>*/}
                </div>
                <div className="container px-5 py-24 mx-auto flex">
                    <form onSubmit={handleSubmit}
                          className="lg:w-1/3 md:w-1/2 bg-white rounded-xl p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 shadow-md">
                        <h2 className="text-lg mb-1 font-bold title-font">{t.send_message}</h2>
                        <p className="leading-relaxed mb-5 text-gray-600">{t.subtitle_contact_right_now}</p>

                        <div
                            className={onSuccessMessage.success === true && onSuccessMessage.message !== "" ? 'flex flex-row text-green-500 items-center' : 'hidden'}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                                 stroke="currentColor" className="size-6">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                            </svg>

                            <h2 className="ml-1">{onSuccessMessage.message}</h2>
                        </div>

                        <div
                            className={!onSuccessMessage.success && onSuccessMessage.message !== "" ? 'flex flex-row text-red-500 items-center' : 'hidden'}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                                 stroke="currentColor" className="size-6">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"/>
                            </svg>


                            <h2 className="ml-1">{onSuccessMessage.message}</h2>
                        </div>

                        <div className="relative mb-4">
                            <label htmlFor="email" className="leading-7 text-sm text-gray-600">Email</label>
                            <input type="email" id="email" name="email" onChange={handleChange('email')}
                                   value={formContact.email}
                                   className="w-full bg-white rounded-xl border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
                        </div>
                        <div className="relative mb-4">
                            <label htmlFor="subject" className="leading-7 text-sm text-gray-600">{t.subject}</label>
                            <input id="subject" name="subject"
                                   onChange={handleChange('subject')}
                                   value={formContact.subject}
                                   className="w-full bg-white rounded-xl border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"></input>
                        </div>
                        <div className="relative mb-4">
                            <label htmlFor="message" className="leading-7 text-sm text-gray-600">{t.message}</label>
                            <textarea id="message" name="message"
                                      onChange={handleChange('message')}
                                      value={formContact.message}
                                      className="w-full bg-white rounded-xl border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-500 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
                        </div>
                        <button
                            className="text-white bg-blue border-0 py-2 px-6 focus:outline-none rounded-xl text-lg">{t.btn_send_message}
                        </button>
                        {/*<p className="text-xs text-gray-500 mt-3">Chicharrones blog helvetica normcore iceland tousled*/}
                        {/*    brook viral artisan.</p>*/}
                    </form>
                </div>
            </section>

            <Footer/>
        </div>
    )
}