import DropDown from "./DropDown";
import options from "../assets/options.png";
import {useEffect, useState, Fragment, useContext} from "react";
import flag_ro from "../components/svg/flag-romania.svg";
import flag_usa from "../components/svg/flag-us.svg";
import {useLocation, useNavigate} from "react-router-dom"
import logo from "./svg/logo.png"
import LANGUAGES from "../helpers/translate";
import {Menu, Transition} from '@headlessui/react'
import {LanguageContext} from "../helpers/LanguageContext";

export default function Sidebar(history) {
    const navigate = useNavigate()

    function changeRoute(page) {
        navigate(page)
    }

    const [isOpen, setIsOpen] = useState(false);

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        if (isOpen) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }

        // Cleanup function to remove the class when the component unmounts
        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, [isOpen]);

    // const [currentLanguage, setCurrentLanguage] = useState("en"); // Default language is English.
    const { currentLanguage, changeLanguage } = useContext(LanguageContext);

    const t = LANGUAGES[currentLanguage]; // Active translations.

    const [activeRoute, setActiveRoute] = useState('');

    useEffect(() => {
        // Setează ruta curentă din browser
        setActiveRoute(window.location.pathname);
    }, []);

    const handleRouteChange = (route) => {
        setActiveRoute(route); // Actualizează ruta activă
        changeRoute(route); // Schimbă pagina
    };

    const location = useLocation()

    return (
        <div>
            <div className="fixed top-0 h-1/12 object-cover w-full z-50">
                {/* TOP */}
                <div className="top-0 w-full">
                    <div className="bg-blue">
                        <div
                            className="relative w-11/12 z-20 sm:w-11/12 2xl:px-12 2xl:w-full md:px-0 flex items-center justify-center md:justify-between mx-auto py-4">
                            <button className="md:hidden absolute menu-button -left-1" onClick={toggleSidebar}>
                                {/*<span role="img" aria-label="menu">🍔</span>*/}
                                <span className="text-white"><svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="w-6 text-white"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        stroke="#fff"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M4 6h16M4 12h16M4 18h16"
                                    ></path>
                                </svg>
                                </span>
                            </button>
                            <div className={`sidebar bg-blue ${isOpen ? 'open' : ''}`}>
                                <button className="close-button float-right" onClick={toggleSidebar}>
                                    {/*<span role="img" aria-label="close">X</span>*/}
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         strokeWidth={1.5} stroke="currentColor" className="w-7">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12"/>
                                    </svg>
                                </button>
                                <button className=" px-4 pt-3 flex flex-col justify-center">
                                {/*<span className="text-xl md:text-lg text-white font-cormorant"*/}
                                {/*      onClick={() => changeRoute("/")}>Paul Moise</span>*/}
                                {/*    <span className="text-[10px] text-white uppercase font-bold"*/}
                                {/*          onClick={() => changeRoute("/")}>Cabinet avocatura</span>*/}
                                {/*    <img src={logo} alt='Logo' className="w-48" />*/}
                                </button>
                                <div className="sidebar-content text-md space-y-4 mt-6">
                                    <ul>
                                        <li onClick={() => changeRoute("/")} >
                                            <span className={`nav-item font-semibold ${location.pathname === '/' ? 'active' : ''}`}>
                                                {t.home}
                                            </span>
                                        </li>
                                        <li onClick={() => changeRoute("/despre-noi")} className="pt-3">
                                            <span className={`nav-item font-semibold ${location.pathname === '/despre-noi' ? 'active' : ''}`}>
                                                {t.about_us}
                                            </span>
                                        </li>
                                        <li onClick={() => changeRoute("/domenii/activitate")} className="pt-3">
                                            <span className={`nav-item font-semibold ${location.pathname === '/domenii/activitate' ? 'active' : ''}`}>
                                                {t.areas_of_activity}
                                            </span>
                                        </li>
                                        <li onClick={() => changeRoute("/blogs")} className="pt-3">
                                            <span className={`nav-item font-semibold ${location.pathname === '/blogs' ? 'active' : ''}`}>
                                                Blog
                                            </span>
                                        </li>
                                        <li onClick={() => changeRoute("/pro-bono")} className="pt-3">
                                            <span className={`nav-item font-semibold ${location.pathname === '/pro-bono' ? 'active' : ''}`}>
                                                Pro Bono
                                            </span>
                                        </li>
                                    </ul>

                                    <div className="mt-6">
                                        <button
                                            className="w-full bg-white rounded-xl text-white text-xs py-3.5 px-4 mt-4">
                                    <span className="color-blue font-bold opacity-100"
                                          onClick={() => changeRoute("/contact")}>{t.contact_us}</span>
                                        </button>
                                        {/*<ul className="flex flex-col text-base text-white font-semibold mt-4">*/}
                                        {/*    <li className="flex flex-row">*/}
                                        {/*        <img className="w-5" src={flag_ro}/>*/}
                                        {/*        <span className="ml-1.5">RO</span>*/}
                                        {/*    </li>*/}
                                        {/*    /!*<li className="flex flex-row">*!/*/}
                                        {/*    /!*    <img className="w-4" src={flag_usa} />*!/*/}
                                        {/*    /!*    <span>EN</span>*!/*/}
                                        {/*    /!*</li>*!/*/}
                                        {/*</ul>*/}
                                        <ul className="flex flex-col text-base text-white font-semibold">
                                            {/*<li className="flex flex-row">*/}
                                            {/*    <img className="w-5" src={flag_ro}/>*/}
                                            {/*    <span className="ml-1.5">RO</span>*/}
                                            {/*</li>*/}
                                            <div className="pt-4">
                                                <Menu as="div" className="relative w-full inline-block text-left">
                                                    <div>
                                                        <Menu.Button
                                                            className="inline-flex w-full justify-start rounded-md bg-white/20 text-sm font-medium text-white hover:bg-white/30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75">
                                                            {currentLanguage === "ro" ?
                                                                <div className="inline-flex px-3 py-1.5 ">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        className="w-5 "
                                                                        viewBox="0 0 36 36"
                                                                    >
                                                                        <path fill="#002B7F"
                                                                              d="M4 5a4 4 0 0 0-4 4v18a4 4 0 0 0 4 4h8V5z"></path>
                                                                        <path fill="#FCD116"
                                                                              d="M12 5h12v26H12z"></path>
                                                                        <path fill="#CE1126"
                                                                              d="M32 5h-8v26h8a4 4 0 0 0 4-4V9a4 4 0 0 0-4-4"></path>
                                                                    </svg>
                                                                    <span className="ml-1.5">Romana</span>
                                                                </div> : <div className="inline-flex px-3 py-1.5 ">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        className="w-5"
                                                                        fill="none"
                                                                        viewBox="0 -4 28 28"
                                                                    >
                                                                        <g clipPath="url(#clip0_503_3486)">
                                                                            <rect width="28" height="20"
                                                                                  fill="#fff" rx="2"></rect>
                                                                            <mask
                                                                                id="mask0_503_3486"
                                                                                width="28"
                                                                                height="20"
                                                                                x="0"
                                                                                y="0"
                                                                                maskUnits="userSpaceOnUse"
                                                                                style={{maskType: "alpha"}}
                                                                            >
                                                                                <rect width="28" height="20"
                                                                                      fill="#fff" rx="2"></rect>
                                                                            </mask>
                                                                            <g mask="url(#mask0_503_3486)">
                                                                                <path
                                                                                    fill="#D02F44"
                                                                                    fillRule="evenodd"
                                                                                    d="M28 0H0v1.333h28zm0 2.667H0V4h28zM0 5.333h28v1.334H0zM28 8H0v1.333h28zM0 10.667h28V12H0zm28 2.666H0v1.334h28zM0 16h28v1.333H0zm28 2.667H0V20h28z"
                                                                                    clipRule="evenodd"
                                                                                ></path>
                                                                                <path fill="#46467F"
                                                                                      d="M0 0h12v9.333H0z"></path>
                                                                                <g filter="url(#filter0_d_503_3486)">
                                                                                    <path
                                                                                        fill="url(#paint0_linear_503_3486)"
                                                                                        fillRule="evenodd"
                                                                                        d="M2.667 2a.667.667 0 1 1-1.334 0 .667.667 0 0 1 1.334 0m2.666 0A.667.667 0 1 1 4 2a.667.667 0 0 1 1.333 0m2 .667a.667.667 0 1 0 0-1.334.667.667 0 0 0 0 1.334M10.667 2a.667.667 0 1 1-1.334 0 .667.667 0 0 1 1.334 0M3.333 4a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333m3.334-.667a.667.667 0 1 1-1.334 0 .667.667 0 0 1 1.334 0m2 .667a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333m2 .667a.667.667 0 1 1-1.334 0 .667.667 0 0 1 1.334 0m-3.334.666a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333m-2-.666a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0M2 5.333A.667.667 0 1 0 2 4a.667.667 0 0 0 0 1.333M4 6a.667.667 0 1 1-1.333 0A.667.667 0 0 1 4 6m2 .667a.667.667 0 1 0 0-1.334.667.667 0 0 0 0 1.334M9.333 6A.667.667 0 1 1 8 6a.667.667 0 0 1 1.333 0M10 8a.667.667 0 1 0 0-1.333A.667.667 0 0 0 10 8m-2-.667a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0M4.667 8a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333m-2-.667a.667.667 0 1 1-1.334 0 .667.667 0 0 1 1.334 0"
                                                                                        clipRule="evenodd"
                                                                                    ></path>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                        <defs>
                                                                            <linearGradient
                                                                                id="paint0_linear_503_3486"
                                                                                x1="1.333"
                                                                                x2="1.333"
                                                                                y1="1.333"
                                                                                y2="8"
                                                                                gradientUnits="userSpaceOnUse"
                                                                            >
                                                                                <stop stopColor="#fff"></stop>
                                                                                <stop offset="1"
                                                                                      stopColor="#F0F0F0"></stop>
                                                                            </linearGradient>
                                                                            <clipPath id="clip0_503_3486">
                                                                                <rect width="28" height="20"
                                                                                      fill="#fff" rx="2"></rect>
                                                                            </clipPath>
                                                                            <filter
                                                                                id="filter0_d_503_3486"
                                                                                width="9.333"
                                                                                height="7.667"
                                                                                x="1.333"
                                                                                y="1.333"
                                                                                colorInterpolationFilters="sRGB"
                                                                                filterUnits="userSpaceOnUse"
                                                                            >
                                                                                <feFlood floodOpacity="0"
                                                                                         result="BackgroundImageFix"></feFlood>
                                                                                <feColorMatrix
                                                                                    in="SourceAlpha"
                                                                                    result="hardAlpha"
                                                                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                                                ></feColorMatrix>
                                                                                <feOffset dy="1"></feOffset>
                                                                                <feColorMatrix
                                                                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"></feColorMatrix>
                                                                                <feBlend
                                                                                    in2="BackgroundImageFix"
                                                                                    result="effect1_dropShadow_503_3486"
                                                                                ></feBlend>
                                                                                <feBlend
                                                                                    in="SourceGraphic"
                                                                                    in2="effect1_dropShadow_503_3486"
                                                                                    result="shape"
                                                                                ></feBlend>
                                                                            </filter>
                                                                        </defs>
                                                                    </svg>
                                                                    <span className="ml-1.5">English</span>
                                                                </div>}

                                                        </Menu.Button>
                                                    </div>
                                                    <Transition
                                                        as={Fragment}
                                                        enter="transition ease-out duration-100"
                                                        enterFrom="transform opacity-0 scale-95"
                                                        enterTo="transform opacity-100 scale-100"
                                                        leave="transition ease-in duration-75"
                                                        leaveFrom="transform opacity-100 scale-100"
                                                        leaveTo="transform opacity-0 scale-95"
                                                    >
                                                        <Menu.Items
                                                            className=" right-0 mt-2 w-full origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                                                            <div className="px-1 py-1 ">
                                                                <Menu.Item>
                                                                    {({active}) => (
                                                                        <button onClick={() => changeLanguage('ro')}
                                                                                className={`${
                                                                                    active ? 'bg-blue text-white' : 'text-gray-900'
                                                                                } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                                        >
                                                                            {/*{active ? (*/}
                                                                            {/*    <EditActiveIcon*/}
                                                                            {/*        className="mr-2 h-5 w-5"*/}
                                                                            {/*        aria-hidden="true"*/}
                                                                            {/*    />*/}
                                                                            {/*) : (*/}
                                                                            {/*    <EditInactiveIcon*/}
                                                                            {/*        className="mr-2 h-5 w-5"*/}
                                                                            {/*        aria-hidden="true"*/}
                                                                            {/*    />*/}
                                                                            {/*)}*/}
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                className="w-6 "
                                                                                viewBox="0 0 36 36"
                                                                            >
                                                                                <path fill="#002B7F"
                                                                                      d="M4 5a4 4 0 0 0-4 4v18a4 4 0 0 0 4 4h8V5z"></path>
                                                                                <path fill="#FCD116"
                                                                                      d="M12 5h12v26H12z"></path>
                                                                                <path fill="#CE1126"
                                                                                      d="M32 5h-8v26h8a4 4 0 0 0 4-4V9a4 4 0 0 0-4-4"></path>
                                                                            </svg>
                                                                            <span className="ml-1.5">Romana</span>
                                                                        </button>
                                                                    )}
                                                                </Menu.Item>
                                                                <Menu.Item>
                                                                    {({active}) => (
                                                                        <button onClick={() => changeLanguage('en')}
                                                                                className={`${
                                                                                    active ? 'bg-blue text-white' : 'text-gray-900'
                                                                                } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                                        >
                                                                            {/*{active ? (*/}
                                                                            {/*    <DuplicateActiveIcon*/}
                                                                            {/*        className="mr-2 h-5 w-5"*/}
                                                                            {/*        aria-hidden="true"*/}
                                                                            {/*    />*/}
                                                                            {/*) : (*/}
                                                                            {/*    <DuplicateInactiveIcon*/}
                                                                            {/*        className="mr-2 h-5 w-5"*/}
                                                                            {/*        aria-hidden="true"*/}
                                                                            {/*    />*/}
                                                                            {/*)}*/}
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                className="w-6"
                                                                                fill="none"
                                                                                viewBox="0 -4 28 28"
                                                                            >
                                                                                <g clipPath="url(#clip0_503_3486)">
                                                                                    <rect width="28" height="20"
                                                                                          fill="#fff" rx="2"></rect>
                                                                                    <mask
                                                                                        id="mask0_503_3486"
                                                                                        width="28"
                                                                                        height="20"
                                                                                        x="0"
                                                                                        y="0"
                                                                                        maskUnits="userSpaceOnUse"
                                                                                        style={{maskType: "alpha"}}
                                                                                    >
                                                                                        <rect width="28" height="20"
                                                                                              fill="#fff" rx="2"></rect>
                                                                                    </mask>
                                                                                    <g mask="url(#mask0_503_3486)">
                                                                                        <path
                                                                                            fill="#D02F44"
                                                                                            fillRule="evenodd"
                                                                                            d="M28 0H0v1.333h28zm0 2.667H0V4h28zM0 5.333h28v1.334H0zM28 8H0v1.333h28zM0 10.667h28V12H0zm28 2.666H0v1.334h28zM0 16h28v1.333H0zm28 2.667H0V20h28z"
                                                                                            clipRule="evenodd"
                                                                                        ></path>
                                                                                        <path fill="#46467F"
                                                                                              d="M0 0h12v9.333H0z"></path>
                                                                                        <g filter="url(#filter0_d_503_3486)">
                                                                                            <path
                                                                                                fill="url(#paint0_linear_503_3486)"
                                                                                                fillRule="evenodd"
                                                                                                d="M2.667 2a.667.667 0 1 1-1.334 0 .667.667 0 0 1 1.334 0m2.666 0A.667.667 0 1 1 4 2a.667.667 0 0 1 1.333 0m2 .667a.667.667 0 1 0 0-1.334.667.667 0 0 0 0 1.334M10.667 2a.667.667 0 1 1-1.334 0 .667.667 0 0 1 1.334 0M3.333 4a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333m3.334-.667a.667.667 0 1 1-1.334 0 .667.667 0 0 1 1.334 0m2 .667a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333m2 .667a.667.667 0 1 1-1.334 0 .667.667 0 0 1 1.334 0m-3.334.666a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333m-2-.666a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0M2 5.333A.667.667 0 1 0 2 4a.667.667 0 0 0 0 1.333M4 6a.667.667 0 1 1-1.333 0A.667.667 0 0 1 4 6m2 .667a.667.667 0 1 0 0-1.334.667.667 0 0 0 0 1.334M9.333 6A.667.667 0 1 1 8 6a.667.667 0 0 1 1.333 0M10 8a.667.667 0 1 0 0-1.333A.667.667 0 0 0 10 8m-2-.667a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0M4.667 8a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333m-2-.667a.667.667 0 1 1-1.334 0 .667.667 0 0 1 1.334 0"
                                                                                                clipRule="evenodd"
                                                                                            ></path>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                                <defs>
                                                                                    <linearGradient
                                                                                        id="paint0_linear_503_3486"
                                                                                        x1="1.333"
                                                                                        x2="1.333"
                                                                                        y1="1.333"
                                                                                        y2="8"
                                                                                        gradientUnits="userSpaceOnUse"
                                                                                    >
                                                                                        <stop stopColor="#fff"></stop>
                                                                                        <stop offset="1"
                                                                                              stopColor="#F0F0F0"></stop>
                                                                                    </linearGradient>
                                                                                    <clipPath id="clip0_503_3486">
                                                                                        <rect width="28" height="20"
                                                                                              fill="#fff" rx="2"></rect>
                                                                                    </clipPath>
                                                                                    <filter
                                                                                        id="filter0_d_503_3486"
                                                                                        width="9.333"
                                                                                        height="7.667"
                                                                                        x="1.333"
                                                                                        y="1.333"
                                                                                        colorInterpolationFilters="sRGB"
                                                                                        filterUnits="userSpaceOnUse"
                                                                                    >
                                                                                        <feFlood floodOpacity="0"
                                                                                                 result="BackgroundImageFix"></feFlood>
                                                                                        <feColorMatrix
                                                                                            in="SourceAlpha"
                                                                                            result="hardAlpha"
                                                                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                                                        ></feColorMatrix>
                                                                                        <feOffset dy="1"></feOffset>
                                                                                        <feColorMatrix
                                                                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"></feColorMatrix>
                                                                                        <feBlend
                                                                                            in2="BackgroundImageFix"
                                                                                            result="effect1_dropShadow_503_3486"
                                                                                        ></feBlend>
                                                                                        <feBlend
                                                                                            in="SourceGraphic"
                                                                                            in2="effect1_dropShadow_503_3486"
                                                                                            result="shape"
                                                                                        ></feBlend>
                                                                                    </filter>
                                                                                </defs>
                                                                            </svg>
                                                                            <span className="ml-1.5">English</span>
                                                                        </button>
                                                                    )}
                                                                </Menu.Item>
                                                            </div>


                                                        </Menu.Items>
                                                    </Transition>
                                                </Menu>
                                            </div>
                                            {/*<li className="flex flex-row">*/}
                                            {/*    <img className="w-4" src={flag_usa} />*/}
                                            {/*    <span>EN</span>*/}
                                            {/*</li>*/}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {isOpen && <div className="overlay relative" onClick={toggleSidebar}></div>}
                            <button onClick={() => {
                                changeRoute('/')
                            }} className="flex flex-col justify-center">
                                {/*<span className="text-2xl text-white font-cormorant"*/}
                                {/*      onClick={() => changeRoute("/")}>Paul Moise</span>*/}
                                {/*<span className="text-[12px] text-white uppercase font-bold"*/}
                                {/*      onClick={() => changeRoute("/")}>Cabinet avocatura</span>*/}
                                <img src={logo} alt='Logo' className="w-56 md:w-40 lg:w-56"/>
                            </button>
                            <a href="tel:+40743121815"
                               className="flex md:hidden flex-col justify-center absolute color-blue right-0 bg-white p-2 rounded-full">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth={1.5} stroke="currentColor" className="size-6">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"/>
                                </svg>

                            </a>

                            <div className="flex-row items-center hidden md:flex">
                                <ul className="flex flex-row space-x-4 lg:space-x-6 lg:text-sm md:text-[12px] text-white font-semibold">
                                    <li className={location.pathname === '/' ? 'nav-item active' : ''}
                                        onClick={() => changeRoute("/")}>{t.home}</li>
                                    <li className={location.pathname === '/despre-noi' ? 'nav-item active' : ''}
                                        onClick={() => changeRoute("/despre-noi")}>{t.about_us}</li>
                                    <li className={location.pathname === '/domenii/activitate' ? 'nav-item active' : ''}
                                        onClick={() => changeRoute("/domenii/activitate")}>{t.areas_of_activity}</li>
                                    <li className={location.pathname === '/blogs' ? 'nav-item active' : ''}
                                        onClick={() => changeRoute("/blogs")}>Blog
                                    </li>
                                    <li className={location.pathname === '/pro-bono' ? 'nav-item active' : ''}
                                        onClick={() => changeRoute("/pro-bono")}>Pro Bono
                                    </li>
                                </ul>
                                <button className="ml-8 bg-white rounded-xl text-white text-xs py-3.5 px-4">
                                    <span className="color-blue font-bold opacity-100"
                                          onClick={() => changeRoute("/contact")}>{t.contact_us}</span>
                                </button>
                                <div className="flex flex-col items-center w-16">
                                    <ul className="flex flex-col text-base text-white font-semibold">
                                        {/*<li className="flex flex-row">*/}
                                        {/*    <img className="w-5" src={flag_ro}/>*/}
                                        {/*    <span className="ml-1.5">RO</span>*/}
                                        {/*</li>*/}
                                        <div className="pl-1.5">
                                            <Menu as="div" className="relative inline-block text-left">
                                                <div>
                                                    <Menu.Button
                                                        className="inline-flex w-full justify-center rounded-md bg-white/20 text-sm font-medium text-white hover:bg-white/30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75">
                                                        {currentLanguage === "ro" ?
                                                            <div className="inline-flex px-1.5 py-1.5 ">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    className="w-5 "
                                                                    viewBox="0 0 36 36"
                                                                >
                                                                    <path fill="#002B7F"
                                                                          d="M4 5a4 4 0 0 0-4 4v18a4 4 0 0 0 4 4h8V5z"></path>
                                                                    <path fill="#FCD116"
                                                                          d="M12 5h12v26H12z"></path>
                                                                    <path fill="#CE1126"
                                                                          d="M32 5h-8v26h8a4 4 0 0 0 4-4V9a4 4 0 0 0-4-4"></path>
                                                                </svg>
                                                                <span className="ml-1.5">RO</span>
                                                            </div> : <div className="inline-flex px-1.5 py-1.5 ">
                                                                <img className="w-5" src={flag_usa}/>
                                                                <span className="ml-1.5">EN</span>
                                                            </div>}

                                                    </Menu.Button>
                                                </div>
                                                <Transition
                                                    as={Fragment}
                                                    enter="transition ease-out duration-100"
                                                    enterFrom="transform opacity-0 scale-95"
                                                    enterTo="transform opacity-100 scale-100"
                                                    leave="transition ease-in duration-75"
                                                    leaveFrom="transform opacity-100 scale-100"
                                                    leaveTo="transform opacity-0 scale-95"
                                                >
                                                    <Menu.Items
                                                        className="absolute right-0 mt-2 w-28 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                                                        <div className="px-1 py-1 ">
                                                            <Menu.Item>
                                                            {({active}) => (
                                                                <button onClick={() => changeLanguage('ro')}
                                                                        className={`${
                                                                            active ? 'bg-blue text-white' : 'text-gray-900'
                                                                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                                >
                                                                    {/*{active ? (*/}
                                                                    {/*    <EditActiveIcon*/}
                                                                    {/*        className="mr-2 h-5 w-5"*/}
                                                                    {/*        aria-hidden="true"*/}
                                                                    {/*    />*/}
                                                                    {/*) : (*/}
                                                                    {/*    <EditInactiveIcon*/}
                                                                    {/*        className="mr-2 h-5 w-5"*/}
                                                                    {/*        aria-hidden="true"*/}
                                                                    {/*    />*/}
                                                                    {/*)}*/}
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        className="w-5 "
                                                                        viewBox="0 0 36 36"
                                                                    >
                                                                        <path fill="#002B7F"
                                                                              d="M4 5a4 4 0 0 0-4 4v18a4 4 0 0 0 4 4h8V5z"></path>
                                                                        <path fill="#FCD116"
                                                                              d="M12 5h12v26H12z"></path>
                                                                        <path fill="#CE1126"
                                                                              d="M32 5h-8v26h8a4 4 0 0 0 4-4V9a4 4 0 0 0-4-4"></path>
                                                                    </svg>
                                                                    <span className="ml-1.5">RO</span>
                                                                </button>
                                                            )}
                                                            </Menu.Item>
                                                            <Menu.Item>
                                                                {({active}) => (
                                                                    <button onClick={() => changeLanguage('en')}
                                                                            className={`${
                                                                                active ? 'bg-blue text-white' : 'text-gray-900'
                                                                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                                    >
                                                                        {/*{active ? (*/}
                                                                        {/*    <DuplicateActiveIcon*/}
                                                                        {/*        className="mr-2 h-5 w-5"*/}
                                                                        {/*        aria-hidden="true"*/}
                                                                        {/*    />*/}
                                                                        {/*) : (*/}
                                                                        {/*    <DuplicateInactiveIcon*/}
                                                                        {/*        className="mr-2 h-5 w-5"*/}
                                                                        {/*        aria-hidden="true"*/}
                                                                        {/*    />*/}
                                                                        {/*)}*/}
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            className="w-5"
                                                                            fill="none"
                                                                            viewBox="0 -4 28 28"
                                                                        >
                                                                            <g clipPath="url(#clip0_503_3486)">
                                                                                <rect width="28" height="20"
                                                                                      fill="#fff" rx="2"></rect>
                                                                                <mask
                                                                                    id="mask0_503_3486"
                                                                                    width="28"
                                                                                    height="20"
                                                                                    x="0"
                                                                                    y="0"
                                                                                    maskUnits="userSpaceOnUse"
                                                                                    style={{maskType: "alpha"}}
                                                                                >
                                                                                    <rect width="28" height="20"
                                                                                          fill="#fff" rx="2"></rect>
                                                                                </mask>
                                                                                <g mask="url(#mask0_503_3486)">
                                                                                    <path
                                                                                        fill="#D02F44"
                                                                                        fillRule="evenodd"
                                                                                        d="M28 0H0v1.333h28zm0 2.667H0V4h28zM0 5.333h28v1.334H0zM28 8H0v1.333h28zM0 10.667h28V12H0zm28 2.666H0v1.334h28zM0 16h28v1.333H0zm28 2.667H0V20h28z"
                                                                                        clipRule="evenodd"
                                                                                    ></path>
                                                                                    <path fill="#46467F"
                                                                                          d="M0 0h12v9.333H0z"></path>
                                                                                    <g filter="url(#filter0_d_503_3486)">
                                                                                        <path
                                                                                            fill="url(#paint0_linear_503_3486)"
                                                                                            fillRule="evenodd"
                                                                                            d="M2.667 2a.667.667 0 1 1-1.334 0 .667.667 0 0 1 1.334 0m2.666 0A.667.667 0 1 1 4 2a.667.667 0 0 1 1.333 0m2 .667a.667.667 0 1 0 0-1.334.667.667 0 0 0 0 1.334M10.667 2a.667.667 0 1 1-1.334 0 .667.667 0 0 1 1.334 0M3.333 4a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333m3.334-.667a.667.667 0 1 1-1.334 0 .667.667 0 0 1 1.334 0m2 .667a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333m2 .667a.667.667 0 1 1-1.334 0 .667.667 0 0 1 1.334 0m-3.334.666a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333m-2-.666a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0M2 5.333A.667.667 0 1 0 2 4a.667.667 0 0 0 0 1.333M4 6a.667.667 0 1 1-1.333 0A.667.667 0 0 1 4 6m2 .667a.667.667 0 1 0 0-1.334.667.667 0 0 0 0 1.334M9.333 6A.667.667 0 1 1 8 6a.667.667 0 0 1 1.333 0M10 8a.667.667 0 1 0 0-1.333A.667.667 0 0 0 10 8m-2-.667a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0M4.667 8a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333m-2-.667a.667.667 0 1 1-1.334 0 .667.667 0 0 1 1.334 0"
                                                                                            clipRule="evenodd"
                                                                                        ></path>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                            <defs>
                                                                                <linearGradient
                                                                                    id="paint0_linear_503_3486"
                                                                                    x1="1.333"
                                                                                    x2="1.333"
                                                                                    y1="1.333"
                                                                                    y2="8"
                                                                                    gradientUnits="userSpaceOnUse"
                                                                                >
                                                                                    <stop stopColor="#fff"></stop>
                                                                                    <stop offset="1"
                                                                                          stopColor="#F0F0F0"></stop>
                                                                                </linearGradient>
                                                                                <clipPath id="clip0_503_3486">
                                                                                    <rect width="28" height="20"
                                                                                          fill="#fff" rx="2"></rect>
                                                                                </clipPath>
                                                                                <filter
                                                                                    id="filter0_d_503_3486"
                                                                                    width="9.333"
                                                                                    height="7.667"
                                                                                    x="1.333"
                                                                                    y="1.333"
                                                                                    colorInterpolationFilters="sRGB"
                                                                                    filterUnits="userSpaceOnUse"
                                                                                >
                                                                                    <feFlood floodOpacity="0"
                                                                                             result="BackgroundImageFix"></feFlood>
                                                                                    <feColorMatrix
                                                                                        in="SourceAlpha"
                                                                                        result="hardAlpha"
                                                                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                                                    ></feColorMatrix>
                                                                                    <feOffset dy="1"></feOffset>
                                                                                    <feColorMatrix
                                                                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"></feColorMatrix>
                                                                                    <feBlend
                                                                                        in2="BackgroundImageFix"
                                                                                        result="effect1_dropShadow_503_3486"
                                                                                    ></feBlend>
                                                                                    <feBlend
                                                                                        in="SourceGraphic"
                                                                                        in2="effect1_dropShadow_503_3486"
                                                                                        result="shape"
                                                                                    ></feBlend>
                                                                                </filter>
                                                                            </defs>
                                                                        </svg>
                                                                        <span className="ml-1.5">EN</span>
                                                                    </button>
                                                                )}
                                                            </Menu.Item>
                                                        </div>


                                                    </Menu.Items>
                                                </Transition>
                                            </Menu>
                                        </div>
                                        {/*<li className="flex flex-row">*/}
                                        {/*    <img className="w-4" src={flag_usa} />*/}
                                        {/*    <span>EN</span>*/}
                                        {/*</li>*/}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    )
}